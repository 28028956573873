import { createApp, h } from 'vue';
import GlobalProperties from 'o365.vue.plugins.GlobalProperties.ts';

let pendingPromise;
export async function handleLogin(pOptions = null) {
    if (pendingPromise) {
        return pendingPromise;
    } else {        
        pendingPromise = new Promise(async (res) => {
            let loginHandlerRef;
            const { default: LoginHandler } = await import('o365.vue.components.Login.App.vue');
            const { autoAnimatePlugin } = await import('auto-animate');
            let wasAuthenticated = true;
            const app = await createApp({
                setup() {
                    return () => h(LoginHandler, {
                        initialOptions: pOptions,
                        ref: 'loginHandler', 'onClose': async () => {
                            await app.unmount();
                            backButton.remove();
                            pendingPromise = null;
                            res(wasAuthenticated);
                        }
                    });
                },
                mounted() {
                    loginHandlerRef = this.$refs.loginHandler
                }
            });
            app.use(autoAnimatePlugin);
            app.use(GlobalProperties);

            const backButton = document.createElement('button');
            backButton.id = 'btn-close-auth';
            backButton.textContent = 'Back'; // TODO chevron?
            backButton.classList.add("btn", "btn-link", "ps-0");
            backButton.addEventListener('click', function handleClick(event) {
                wasAuthenticated = false;
                app.unmount()
            });

            const box = document.getElementById('c365-login-dialog-close-btn');
            box.appendChild(backButton);

            window.requestAnimationFrame(() => {
                app.mount('#c365-login-dialog');
            });
        });

        return pendingPromise;
    }
}
